<template>
  <div class="commodity-item" :class="{'commodity-box-2' : module== 2,'commodity-box-3' : module== 3}">
    <div class="commodity-box clear">
      <div class="commodity-list f-l" v-for=" (item,index) in listData " :key="index" @click="mallShow(item)">
        <div class="list-img-box">
          <img :src="item.cover_picture" class="list-img"/>
          <div class="vip-hint-icon" v-if="item.is_vip == 1">VIP</div>

          <div class="sell-out-box align-center" v-if="(parseFloat(item.stock) - parseFloat(item.sold)) < 1">
            <img src="~assets/images/public/sell-out-icon.png" class="sell-out-icon" alt="">
          </div>
        </div>
        <div class="commodity-cont">
          <div class="commodity-info">
            <div class="commodity-tit-box">
              <div class="commodity-grade" v-if=" item.rule ">Lv{{ item.rule.detail.name }}</div>
              <div class="commodity-tit white-space-2">{{ item.title }}</div>
            </div>
          </div>
          <div class="integral-num  vertical-center">{{ item.credit }}积分
            <img :src="item.user_label.picture" v-if="item.user_label && item.user_label.picture" class="labelimg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mallList",
  props: ['list', 'module'],
  data() {
    return {
      listData: []
    }
  },
  created() {
    if (this.list.length) {
      this.listData = this.list
    }
  },
  methods: {
    //  详情点击
    mallShow(data) {
      this.$router.push({path: `/malls/show/${data.uuid}`})
    }
  },
  watch: {
    list(val, oldVal) {
      this.listData = val;
    }
  }

}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.commodity-item {
  margin: 0 auto;

  .commodity-box {
    margin-left: -16/$r;

    .commodity-list {
      width: 336/$r;
      height: 440/$r;
      background: #FFFFFF;
      box-shadow: -1/$r 0 6/$r 0 rgba(189, 189, 189, 0.66);
      border-radius: 10/$r;
      box-sizing: border-box;
      margin-left: 16/$r;
      margin-bottom: 20/$r;
      position: relative;

      .list-img-box {
        position: relative;
        background: linear-gradient(0deg, #F7F7F7 0%, rgba(255, 255, 255, 0) 100%);

        .list-img {
          width: 320/$r;
          height: 320/$r;
          background: #E6E6E6;
          display: block;
          margin: 0 auto;
          border-radius: 8/$r 8/$r 0 0 ;
          overflow: hidden;
        }

        .vip-hint-icon {
          width: 72/$r;
          height: 30/$r;
          line-height: 30/$r;
          background: linear-gradient(90.92deg, #E8AF54 0%, #FCE0AB 100%);
          border-radius: 0 8/$r 0 8/$r;
          font-style: italic;
          color: #6E3F0F;
          font-size: 24/$r;
          font-weight: 500;
          text-align: center;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }

        .sell-out-box {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          border-radius: 8/$r 8/$r 0 0 ;
          .sell-out-icon{
            width: 180/$r;
            height: 140/$r;
            display: block;
          }
        }
      }

      .commodity-cont {
        width: 100%;
        height: 123/$r;
        box-shadow: 0 -3/$r 13/$r 0 rgba(120, 120, 120, 0.1);
        border-radius: 0 0 8/$r 8/$r;
        position: relative;
        z-index: 2;
      }

      .commodity-info {
        margin: 0 10/$r;
        padding-top: 14/$r;
        box-sizing: border-box;

        .commodity-tit-box {
          position: relative;

          .commodity-grade {
            width: 56/$r;
            height: 26/$r;
            line-height: 26/$r;
            text-align: center;
            background: linear-gradient(-29deg, #FF6A29 0%, #FFB267 100%);
            border-radius: 4/$r;
            font-size: 22/$r;
            font-weight: 400;
            color: #FFFFFF;
            position: absolute;
            left: 0;
            top: 4/$r;
          }

          .commodity-tit {
            font-size: 24/$r;
            font-weight: bold;
            color: #404040;
            line-height: 36/$r;
            text-indent: 62/$r;
          }
        }
      }

      .integral-num {
        font-size: 24/$r;
        font-weight: bold;
        line-height: 30/$r;
        color: #F54029;
        position: absolute;
        left: 12/$r;
        bottom: 12/$r;
      }
    }
  }
}

.commodity-box-3 {
  width: 692/$r;

  .commodity-box {
    .commodity-list {
      width: 220/$r;
      height: 342/$r;

      .list-img-box {
        width: 220/$r;
        height: 220/$r;
        overflow: hidden;

        .list-img {
          width: 220/$r;
          height: 220/$r;
          border-radius: 8/$r 8/$r 0 0;
        }
      }

      .commodity-cont {
        .commodity-info {
          .commodity-tit-box {
            .commodity-tit {
              font-size: 24/$r;
              line-height: 36/$r;
            }
          }
        }
      }
    }
  }
}

.commodity-box-2 {
  width: 514/$r;
  .commodity-box {
    margin-left: -26/$r;
    .commodity-list {
      width: 244/$r;
      height: 380/$r;
      margin-left: 26/$r;
      .list-img-box {
        .list-img {
          width: 244/$r;
          height: 244/$r;
        }
      }
      .commodity-cont{
        height: 136/$r;
        .commodity-tit-box{
          .commodity-tit{
            font-size: 26/$r;
          }
        }
        .integral-num{
          bottom: 16/$r;
        }
      }
    }
  }
}

.labelimg{
            width: 88/$r;
            height: 30/$r;
            margin-left: 10/$r;
          }
</style>