<template>
  <div v-show="template_status" class="mall-template">
    <!--提示信息开始-->
    <div class="buy-hint vertical-center">
      <img src="~assets/images/public/exchange-tips-icon.png" class="hint-icon"/>
      <div class="hint-tit">根据自己等级只能兑换相对应的产品</div>
    </div>
    <!--提示信息结束-->

    <div class="mall-cont">
      <div class="mall-cont-l">
        <side-bar :categories="categories"  @categoriesChange="categoriesChange"></side-bar>
      </div>

      <div class="mall-cont-r">
        <!-- 等级导航 -->
        <div class="level-bar-box">
          <level-bar :level="level" @levelChange="levelChange" :level_num="level_num"></level-bar>
        </div>

        <van-list v-model="loading" :finished="finished" :finished-text="mallList.length > 6 ? '没有更多了': ''"
                  :immediate-check="false" @load="onLoad" ref="vanlist">
          <div class="mall-wrap">
            <mall-list :list="mallList" :module="2" ></mall-list>
          </div>
        </van-list>
        <no-data v-if="mallList.length == 0 && finished_status" :bottomBg="bottomBg"></no-data>
      </div>
    </div>
  </div>
</template>

<script>
import mallList from "../../../components/mallList";
import NoData from "@/components/NoData";
import SideBar from "@/components/SideBar";
import LevelBar from "components/LevelBar";
export default {
  name: "index",
  data() {
    return {
      template_status: false,
      categories_tit: '类目',
      level_tit: '等级',
      product_types_tit: '商品类型',
      productTypes: [
        {text: '实体商品', value: 'entity', type: 'entity'},
        {text: '虚拟商品', value: 'virtual', type: 'virtual'}
      ],
      product_types_id: '',
      show: false,
      categories: [],    //商品分类数据
      categories_id: 0,  // 商品分类id
      level: [],     // 等级数组
      level_id: 0,    // 等级id
      category_ids: [],
      rule_ids: [],
      full: 1,
      pageSize: 10,
      mallList: [],  // 商品列表数据
      full_status: true,  //判断分页状态
      loading_status: false,
      loading: false,
      finished: false,
      finished_status: true,
      request_status: true,
      bottomBg: true,
      level_num: -1
    }
  },
  // beforeRouteEnter(to,from,next){
  //   if (to.path == "/malls"){
  //     console.log("111")
  //     to.meta.keepAlive = true
  //     if (!from.meta.keepAlive){
  //       from.meta.keepAlive = true
  //     }
  //     next()
  //   }
  //     next()
  //
  // },
  beforeRouteLeave(to,from,next){
    if (to.path.slice(0,11) == "/malls/show"){
      // to.meta.keepAlive = true
      if (!from.meta.keepAlive){
        from.meta.keepAlive = true
      }
      next()
    }else {
      // from.meta.keepAlive = false
      to.meta.keepAlive = false
      next()
    }
  },
  created() {
    // 获取分类内容
    this.getCategories();

    // 获取等级
    this.getRules();

    // 获取商品列表
    this.getMallList();
  },
  methods: {
    //  获取列表
    getMallList() {
      if(this.request_status){
        this.request_status = false;
        let data = {
          category_ids: this.category_ids,
          rule_ids: this.rule_ids,
          type: this.product_types_id,
          is_top: 1
        };
        let url = this.$api.Goods + '?f=' + this.$handle.encryption(data) + '&pageSize=' + this.pageSize + '&page=' + this.full;
        this.full++;
        this.finished_status = false;
        this.$http.get(url).then(res => {
          if (res.data.success) {
            this.finished_status = true
            this.template_status = true;
            this.loading = false;
            if (res.data.data.data.length) {
              this.finished = false;
              this.mallList = this.mallList.concat(res.data.data.data)
            } else {
              this.finished = true;
            }
          }
          setTimeout(()=>{
            this.request_status = true;
          },300)
        }).catch(()=>{
          setTimeout(()=>{
            this.request_status = true;
          },300)
        })
      }
    },
    // 获取分类内容
    getCategories() {
      this.$http.get(this.$api.Categories).then(res => {
        if (res.data.success) {
          res.data.data.unshift({id: 'all', name: "全部"})
          this.categories = res.data.data;
        }
      })
    },
    // 获取等级
    getRules() {
      let url = this.$api.Rules + '?f=' + this.$handle.encryption({"type": "level"});
      this.$http.get(url).then(res => {
        if (res.data.success) {
          if(res.data.data.length){
            for (let i=0; i< res.data.data.length;i++){
              if(res.data.data[i].detail.name == 0){
                res.data.data.splice(i,1)
              }
            }
          }
          res.data.data.unshift({id: 'all', detail: { name: '全部'} })
          this.level = res.data.data
        }
      })
    },
    // 类目点击选择
    categoriesChange(value) {
      this.category_ids = value;
      this.level_num = value;
      this.rule_ids = [];
      // 获取列表
      this.defaultFun();
      this.getMallList();
    },
    // 等级选择
    levelChange(value) {
      this.rule_ids = value;
      // 获取列表
      this.defaultFun();
      this.getMallList();
    },
    // 商品类型
    typesChange(value) {
      this.product_types_id = value;
      let type = this.productTypes;
      for (let i = 0; i < type.length; i++) {
        if (type[i].type == value) {
          this.product_types_tit = type[i].text
        }
      }
      // 获取列表
      this.defaultFun();
      this.getMallList();
    },
    // 恢复默认状态
    defaultFun() {
      this.mallList = [];
      this.full = 1;
      this.full_status = true;
      this.loading_status = false;
      this.loading = false;
      this.finished= false;
      document.documentElement.scrollTop = 0
    },
    //  底部加载
    onLoad() {
      if(this.finished_status){
        this.getMallList()
      }
    },

  },
  components: {
    mallList,
    NoData,
    SideBar,
    LevelBar
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/malls/index.scss";
.mall-cont{
  width: 750/$r;
  display: flex;
  margin: 0 auto;
}
.mall-cont-l{
  width: 170/$r;
}
.mall-cont-r{
  flex: 1;
  overflow: hidden;
}

/*提示信息样式开始*/
.buy-hint{
  width: 100%;
  height: 60/$r;
  background: #FFEDC2;
  padding:0 34/$r;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .hint-icon{
    width: 30/$r;
    height: 30/$r;
    display: block;
    margin-right: 14/$r;
  }
  .hint-tit{
    line-height: 30/$r;
    color: #4D4D4D;
    font-size: 24/$r;
    font-family: Source Han Sans CN;
  }
}
/*提示信息样式结束*/

.level-bar-box{
  width: 584/$r;
  position: fixed;
  top: 60/$r;
  z-index: 9;
}
</style>