<template>
  <div style="height: 100%">
    <ul class="mall-sidebar" :style="{height: sidebar_height + 'px',top: sidebar_top + 'px'}" id="sidebar">
        <li v-for="(item,index) in sidebarData" :key="index" :class="{'li-act' : sidebar_active == index}" class="sidebar-item" @click="sidebarFun($event,index,item.id)">{{item.name}}</li>
    </ul>
    <div class="sidebar-weight"></div>
  </div>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash'
export default {
  name: "SideBar",
  props: ['categories'],
  data(){
    return{
      sidebar_active: 0,
      sidebarData: [],
      sidebar_height: 0,
      sidebar_top: 0
    }
  },
  mounted() {
    let buy_hint = $('.buy-hint').height();
    let foot_wrap = $('.foot-wrap').height();
    let mall_template = $('.mall-template').height();
    this.sidebar_height = mall_template - buy_hint - foot_wrap;
    this.sidebar_top = buy_hint;
    if(this.categories){
      this.sidebarData = this.categories;
    }
  },
  methods: {
    // 点击选择当前元素
    sidebarFun: _.debounce(function (event,index,id){
      let idArr = [];
      idArr.push(id)
      this.sidebar_active = index;
      this.$nextTick(()=>{
        let sidebar_h = $('#sidebar').height();
        let current_top = event.currentTarget.offsetTop;
        $('#sidebar').animate({
          scrollTop: current_top - (sidebar_h - (event.currentTarget.offsetHeight)*2)/2
        }, 300)
      })
      if(id == 'all'){
        this.$emit('categoriesChange',[])
      }else {
        this.$emit('categoriesChange',idArr)
      }
    },300),
   /* sidebarFun(event,index,id){
      let idArr = [];
      idArr.push(id)
      this.sidebar_active = index;
      this.$nextTick(()=>{
        let sidebar_h = $('#sidebar').height();
        let current_top = event.currentTarget.offsetTop;
        $('#sidebar').animate({
          scrollTop: current_top - (sidebar_h - (event.currentTarget.offsetHeight)*2)/2
          }, 300)
      })
      if(id == 'all'){
        this.$emit('categoriesChange',[])
      }else {
        this.$emit('categoriesChange',idArr)
      }
    },*/
  },
  watch: {
    /*分类id数据*/
    categories(val, oldval){
      this.sidebarData = val;
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.mall-sidebar{
  width: 170/$r;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 6;
  background-color: #F7F7F7;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  li{
    color: #323232;
    font-size: 28/$r;
    height: 90/$r;
    line-height: 90/$r;
    text-align: center;
    position: relative;
  }
  .li-act{
    background-color: #FFFFFF;
  }
  .li-act:before{
    content: " ";
    width: 6/$r;
    height: 90/$r;
    background: #FF6A29;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
  }
}
.sidebar-weight{
  width: 170/$r;
  min-height: 100vh;
  height: 100%;
  background-color: #F7F7F7;
}

.van-sidebar{
  height: 100%;
}
/*.van-sidebar::-webkit-scrollbar { width: 0 !important }
.van-sidebar { -ms-overflow-style: none; }
.van-sidebar { overflow: -moz-scrollbars-none; }*/
</style>
