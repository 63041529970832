<template>
<div class="level-bar">
  <!-- 收起筛选 -->
  <div class="level-bar-hide" v-show="screening_status">
    <ul class="level-item" id="levelbar">
      <li v-for="(item,index) in levelData" :key="index" :class="{'li-act' : level_bar == index}" @click="levelFun($event,index,item.id)">{{item.id != 'all' ? 'Lv' : ''}}{{item.detail.name}}</li>
      <li class="default-li"></li>
    </ul>
    <div class="level-screening-box" @click="screeningFun">
      <div class="more-chapter-track"></div>
      <div class="level-show-icon align-center">
        <img src="~assets/images/public/level-show-icon.png" alt="">
      </div>
    </div>
  </div>

  <!-- 展开 -->
  <div class="level-bar-show" v-show="!screening_status">
    <div class="level-bar-header vertical-center space-between">
      <p>全部分类</p>
      <img src="~assets/images/public/level-hide-icon.png" @click="screeningFun" alt="">
    </div>

    <div class="level-bar-box">
      <ul class="level-bar-cont clear">
        <li v-for="(item,index) in levelData" :key="index" :class="{'li-act' : level_bar == index}" @click="levelMoreFun(index,item.id)">{{item.id != 'all' ? 'Lv' : ''}}{{item.detail.name}}</li>
      </ul>
    </div>
  </div>

  <div class="level-bar-bac" v-show="!screening_status" @click="screening_status = !screening_status"></div>
</div>
</template>

<script>
import $ from 'jquery';
export default {
  name: "LevelBar",
  props: ['level','level_num'],
  data() {
    return{
      level_bar: 0,
      levelData: [],
      screening_status: true
    }
  },
  created() {
    if(this.level){
      this.levelData = this.level
    }
    /*if(this.mallLeave){
      let _this = this;
      this.level_bar = this.mallLeave.level_bar;
      this.$nextTick(()=>{
        setTimeout(()=>{
          let sidebar_w = $('#levelbar').scrollLeft();
          let current_left = $('#levelbar').children('li').eq(_this.level_bar).position().left;
          $('#levelbar').animate({
            scrollLeft: (current_left + sidebar_w)
          },300)
        },200)
      })
      if(this.mallLeave.type == 'all'){
        this.$emit('levelChange',this.mallLeave.ids)
      }
    }*/
  },
  methods: {
    // 选择当前列表
    levelFun(event,index,id){
      let idArr = [];
      idArr.push(id)
      this.level_bar = index;
      let levelData = {
        level_bar: index,
        type: id
      }
      this.$nextTick(()=>{
        let sidebar_w = $('#levelbar').width();
        let current_left = event.currentTarget.offsetLeft;
        $('#levelbar').animate({
          scrollLeft: current_left - (sidebar_w - (event.currentTarget.offsetWidth))/2
        },300)
      })
      if(id == 'all'){
        this.$emit('levelChange',[])
        levelData.ids = []
      }else {
        this.$emit('levelChange',idArr)
        levelData.ids = idArr
      }

    //  存入vuex
      this.$store.dispatch('A_MALL_LEAVE',levelData)
    },
    // 更多筛选点击事件
    levelMoreFun(index,id){
      let idArr = [];
      idArr.push(id)
      this.level_bar = index;
      this.screening_status = true;
      setTimeout(()=>{
        this.$nextTick(()=>{
          let sidebar_w = $('#levelbar').scrollLeft();
          let current_left = $('#levelbar').children().eq(index).position().left;
          $('#levelbar').animate({
            scrollLeft: (current_left + sidebar_w)
          },300)
        })
      },200)
      if(id == 'all'){
        this.$emit('levelChange',[])
      }else {
        this.$emit('levelChange',idArr)
      }
    },
    // 筛选点击
    screeningFun(){
      this.screening_status = !this.screening_status;
    }
  },
  watch: {
    level(val, oldval){
      this.levelData = val
    },
    level_num(val, oldval){
      this.level_bar = 0;
      this.screening_status = true;
      this.$nextTick(()=>{
        let sidebar_w = $('#levelbar').scrollLeft();
        let current_left = $('#levelbar').children().eq(0).position().left;
        $('#levelbar').animate({
          scrollLeft: (current_left + sidebar_w)
        },300)
      })
    }
  },
  computed: {
    mallLeave(){
      return this.$store.getters.mallLeave
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.level-bar{
  position: relative;
}
/* 收起等级筛选 */
.level-bar-hide{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 0 32/$r;
}
.level-item{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: #FFFFFF;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  padding: 24/$r 0;
  position: relative;
  li{
    min-width: 80/$r;
    padding: 3/$r 18/$r;
    box-sizing: border-box;
    flex: 1 0 auto;
    background: #F7F7F7;
    font-size: 24/$r;
    line-height: 24/$r;
    margin-right: 10/$r;
    border-radius: 15/$r;
      color: #808080;
  }

}
.default-li{
  min-width: 0!important;
  width: 50/$r!important;
  background: none!important;
}
.level-screening-box{
  width: 50/$r;
  height: 30/$r;
  position: absolute;
  right: 32/$r;
  top: 24/$r;
  z-index: 2;
  .level-show-icon{
    width: 20/$r;
    height: 30/$r;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #FFFFFF;
    z-index: 2;
    img{
      width: 14/$r;
      height: 8/$r;
      display: block;
    }
  }

  .more-chapter-track{
    width: 20/$r;
    height: 30/$r;
    position: absolute;
    right: 20/$r;
    top: 0;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,.4));
    z-index: 1;
  }
}

/*展开等级筛选*/
.level-bar-show{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding-bottom: 30/$r;
  background-color: #FFFFFF;
}
.level-bar-header{
  padding: 12/$r 32/$r 20/$r 22/$r;
  p{
    font-size: 24/$r;
    font-weight: 400;
    color: #323232;
    line-height: 28/$r;
  }
  img{
    width: 14/$r;
    height: 8/$r;
    display: block;
  }
}

.level-bar-box{
  width: 530/$r;
  margin: 0 auto;
  .level-bar-cont{
    margin-left: -10/$r;
    li{
      width: 80/$r;
      text-align: center;
      box-sizing: border-box;
      flex: 1 0 auto;
      background: #F7F7F7;
      font-size: 24/$r;
      line-height: 24/$r;
      border-radius: 15/$r;
      margin-left: 10/$r;
      float: left;
      margin-bottom: 20/$r;
      padding: 3/$r 0;
        color: #808080;
    }
  }
}
li.li-act{
  color: #FF6A29 !important;
  background: #FFEDC2!important;
}
/*蒙层样式*/
.level-bar-bac{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
//.level-item::-webkit-scrollbar { width: 0 !important }
//.level-item { -ms-overflow-style: none; }
//.level-item { overflow: -moz-scrollbars-none; }
.level-item::-webkit-scrollbar { display: none; }

</style>